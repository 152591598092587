<template>
    <div>

        <a-spin :spinning="loading">
            <a-form ref="form" name="form" :model="formState" @finish="onSearch">
                <a-row>
                    <a-form-item label="分账组名称" class="ui-form__item" name="title">
                        <a-input v-model:value="formState.title" placeholder="请输入分账组名称"></a-input>
                    </a-form-item>
                </a-row>

                <a-row>
                    <a-col :span="18">
                      <a-button type="primary" @click="onRmTap">清除选择</a-button>
                    </a-col>
                    <a-col :span="6" style="text-align: right;">
                        <a-button type="primary" html-type="submit">搜索</a-button>
                        <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
                    </a-col>
                </a-row>
            </a-form>
            <a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination"
                     :rowSelection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'isDefault'">
						{{ record.isDefault ? '是' : '否' }}
					</template>
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
				</template>
			</a-table>
        </a-spin>
    </div>
</template>

<script>
	import {
		ledgerGroupList
	} from '@/service/modules/ledger.js';
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {},
				searchData: {},
				selectedRowKeys: [],
				list: [],
				columns: [{
					title: '分账组名称',
					dataIndex: 'title'
				}, {
					title: '是否为默认分账组',
					key: 'isDefault'
				},{
					title: '创建时间',
					key: 'createTime'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.selectedRowKeys = this.value;
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await ledgerGroupList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSelectChange(key) {
				this.selectedRowKeys = key;
				this.$emit('update:value', this.selectedRowKeys);
			},
      onRmTap() {
        this.selectedRowKeys = null;
        this.$emit('update:value', this.selectedRowKeys);
      }
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
