import { send } from "../index";

//  结算用户列表
export function getLedgerUserList(data, opts = {}) {
    return send({
        url: "/admin/adapayMember/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveLedger(data, opts = {}) {
    return send({
        url: "/admin/adapayMember/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateLedger(data, opts = {}) {
    return send({
        url: "/admin/adapaySettleAccount/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 结算用户详情
export function ledgerDetail(data, opts = {}) {
    return send({
        url: "/admin/adapayMember/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 全局分账方修改金额比例
export function updateScale(data, opts = {}) {
    return send({
        url: "/admin/adapayMember/updateScale.do",
        method: "POST",
        data,
        ...opts,
    });
}


// 删除结算账户
export function deleteLedger(data, opts = {}) {
    return send({
        url: "/admin/adapaySettleAccount/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

//删除分账账户
export function delAdapayMember(data, opts = {}) {
    return send({
        url: "/admin/adapayMember/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}


// 启用结算账户
export function enableLedger(data, opts = {}) {
    return send({
        url: "/admin/adapayMember/pullOnShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 禁用结算账户
export function disabledLedger(data, opts = {}) {
    return send({
        url: "/admin/adapayMember/pullOffShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 分账组列表
export function ledgerGroupList(data, opts = {}) {
    return send({
        url: "/admin/allocateGroup/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}


// 新增分账组
export function saveLedgerGroup(data, opts = {}) {
    return send({
        url: "/admin/allocateGroup/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 编辑分账组
export function updateLedgerGroup(data, opts = {}) {
    return send({
        url: "/admin/allocateGroup/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 删除分账组
export function deleteLedgerGroup(data, opts = {}) {
    return send({
        url: "/admin/allocateGroup/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 分账组用户配置列表
export function ledgerGroupConfigList(data, opts = {}) {
    return send({
        url: "/admin/allocateGroupMember/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 新增分账组用户
export function saveLedgerGroupUser(data, opts = {}) {
    return send({
        url: "/admin/allocateGroupMember/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 删除分账组用户
export function deleteLedgerGroupUser(data, opts = {}) {
    return send({
        url: "/admin/allocateGroupMember/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 修改分账组用户比例
export function updateLedgerGroupUserScale(data, opts = {}) {
    return send({
        url: "/admin/allocateGroupMember/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 商城商品绑定分账组
export function bindAllocateGroup(data, opts = {}) {
    return send({
        url: "/admin/storeGoodsStock/bindAllocateGroup.do",
        method: "POST",
        data,
        ...opts,
    });
}
// 商城商品批量绑定分账组
export function batchBindAllocateGroup(data, opts = {}) {
    return send({
        url: "/admin/storeGoodsStock/batchBindAllocateGroup.do",
        method: "POST",
        data,
        ...opts,
    });
}

// 分账商品列表
export function getAllocateGroupCinemaList(data, opts = {}) {
    return send({
        // url: "/admin/allocateGroupCinema/searchList.do",
        url: "/admin/allocateGroupCinema/listByType.do",
        method: "POST",
        data,
        ...opts,
    });
}

//  分账商品修改分账组
export function allocateGroupCinema(data, opts = {}) {
    return send({
        url: "/admin/allocateGroupCinema/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

//  分账商品修改分账组
export function batchAllocateGroupCinema(data, opts = {}) {
    return send({
        url: "/admin/allocateGroupCinema/batchBindAllocateGroup.do",
        method: "POST",
        data,
        ...opts,
    });
}

//  修改分配影院分账组
export function updateAllocateGroupCinema(data, opts = {}) {
    return send({
        url: "/admin/allocateGroupCinema/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

//  删除分配影院分账组
export function delAllocateGroupCinema(data, opts = {}) {
    return send({
        url: "/admin/allocateGroupCinema/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}


//  更改企业分配用户信息
export function updateCorpMember(data, opts = {}) {
    return send({
        url: "/admin/adapayMember/corpMemberUpdate.do",
        method: "POST",
        data,
        ...opts,
    });
}
